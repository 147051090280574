import {
  MAX,
  SPACING,
  SPACING_HALF,
  SPACING_ZERO,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import { screenXsMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

type StylesProps = {
  shrinkOnMobile: boolean | undefined,
  mobileTextLeft: boolean | undefined,
}

const styles = (
  {
    fontBoldStyles,
    fontNormalStyles,
    ...theme
  }: MergedThemes,
  { mobileTextLeft, shrinkOnMobile }: StylesProps,
) => ({
  content: {
    ...fontNormalStyles,
    flex: '1 1 50%',
    fontSize: px(16),
    wordBreak: 'break-word',
    ...shrinkOnMobile && {
      [`@media (max-width: ${screenXsMax}px)`]: {
        padding: `${px(SPACING_HALF)} 0`,
        textAlign: mobileTextLeft ? 'left' : 'center',
        textOverflow: 'initial',
        whiteSpace: 'normal',
        width: MAX,
      },
    },
  },
  contentLyrics: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
  },
  contentNonLyrics: {
    textAlign: 'right',
  },
  label: {
    ...fontBoldStyles,
    flex: '1 1 auto',
    fontSize: px(16),
    textAlign: 'left',
    ...shrinkOnMobile && {
      [`@media (max-width: ${screenXsMax}px)`]: {
        padding: `${px(SPACING_HALF)} 0`,
        textAlign: mobileTextLeft ? 'left' : 'center',
        width: MAX,
      },
    },
  },
  noPadding: {
    padding: `${px(SPACING_ZERO)} 0`,
  },
  padding: {
    padding: `${px(SPACING)} 0`,
  },
  row: {
    alignItems: 'baseline',
    borderBottom: `1px solid ${theme.branding?.lightestGrayBorder ?? 'transparent'}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: SPACING_ZERO,
    ...shrinkOnMobile && {
      [`@media (max-width: ${screenXsMax}px)`]: {
        display: 'block',
      },
    },
  },
  smallPadding: {
    padding: `${px(SPACING_HALF)} 0`,
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'MetadataBoxRow',
})(styles)
