/* eslint-disable @typescript-eslint/no-shadow */
import { Flexbox, StretchOne } from 'd2/components/Layout'
import { forwardProps } from 'd2/utils/props'
import { isBoolean } from 'lodash-es'
import { memo, useCallback } from 'react'
import { useIsMobile } from 'd2/components/Responsive'
import Divider from 'd2/components/Divider'
import StackedOnPhone from 'd2/components/StackedOnPhone'
import Truncate from 'd2/components/Typography/Truncate'
import useStyles from './styles'
import type { Props } from './types'

const Card = memo<Props>(({
  alignButtons, // Default value set in `styles.tsx`
  bodyAlignCenter,
  bottomButtons,
  children,
  className,
  header,
  hideDivider = false,
  maxHeight,
  noBorder = false,
  noLineMarginBottom,
  noLineMarginTop,
  noVerticalBorder = false,
  paddingSize = 'large',
  testID,
  tinyButtons,
  title: TitleComponent,
  titleAlign,
  topButtons,
  truncate = false,
  usePaper = true,
  ...props
}) => {
  const { classes, cx } = useStyles({ alignButtons })
  const isMobile = useIsMobile()

  const BodyContainer = useCallback(
    // eslint-disable-next-line react-memo/require-memo
    ({ children }: {
      children: any
    }) => bodyAlignCenter
      ? (
        <StretchOne
          stretch='last'
          vertical
        >
          { children }
        </StretchOne>
      )
      : children,
    [bodyAlignCenter],
  )

  const BodyContent = useCallback(
    // eslint-disable-next-line react-memo/require-memo
    ({ children }: {
      children: any
    }) => bodyAlignCenter
      ? (
        <StretchOne
          stretch={2}
          vertical
        >
          { children }
        </StretchOne>
      )
      : children || null,
    [bodyAlignCenter],
  )

  return (
    <div
      className={cx(
        classes.container,
        {
          [classes.containerNoBorder]: noBorder,
          [classes.containerNoVerticalBorders]: noVerticalBorder,
          [classes.containerWithoutPaper]: !usePaper && !noBorder && !noVerticalBorder,
          [classes.maxHeight]: maxHeight,
        },
        className,
      )}
      {...forwardProps(props)}
      data-test-id={testID}
    >
      <BodyContainer>
        { header }
        <BodyContainer>
          <div
            className={cx({
              [classes.body]: paddingSize === 'large' && !isMobile,
              [classes.bodyMobile]: paddingSize === 'large' && isMobile,
              [classes.bodyWithSmallPadding]: paddingSize === 'small' && !isMobile,
              [classes.bodyWithSmallPaddingMobile]: paddingSize === 'small' && isMobile,
              [classes.bodyWithExtraSmallPadding]: paddingSize === 'extraSmall',
              [classes.noPadding]: paddingSize === 'none',
              [classes.noPaddingTopBottom]: paddingSize === 'noneTopBottom',
            })}
          >
            <BodyContent>
              { titleAlign
                ? <Flexbox align={titleAlign}>
                  { TitleComponent }
                </Flexbox>
                : <StackedOnPhone tinyButtons={tinyButtons}>
                  { TitleComponent && (
                    topButtons
                      ? (
                        // This <div> is to fix the Truncate w/ CardTitle being full width and squishing the Card buttons.
                        <div>
                          {
                            truncate
                              ? <Truncate
                                title={TitleComponent}
                              >
                                { TitleComponent }
                              </Truncate>
                              : TitleComponent
                          }
                        </div>
                      )
                      : truncate
                        ? <Truncate
                          title={TitleComponent}
                        >
                          { TitleComponent }
                        </Truncate>
                        : TitleComponent

                  ) }
                  { topButtons && (
                    <div className={classes.buttonsWrapper}>
                      { topButtons }
                    </div>
                  ) }
                </StackedOnPhone> }

              { TitleComponent && <Divider
                className={cx(classes.horizontalLine, {
                  [classes.noMarginBottom]: noLineMarginBottom,
                  [classes.noMarginTop]: noLineMarginTop,
                  [classes.hideHorizontalLine]: hideDivider,
                  [classes.showHorizontalLine]: !hideDivider,
                })}
              /> }
              { bodyAlignCenter
                ? (
                  <Flexbox
                    align='center'
                    secondaryAlign='stretch'
                    vertical
                  >
                    { children }
                  </Flexbox>
                )
                : children }
              { bottomButtons && (
                <div className={cx(classes.buttonsWrapper, classes.bottomButtonsWrapper)}>
                  { isBoolean(bottomButtons) ? topButtons : bottomButtons }
                </div>
              ) }
            </BodyContent>
          </div>
        </BodyContainer>
      </BodyContainer>
    </div>
  )
})

Card.displayName = 'Card'

export default Card

export { default as CardSubSection } from './CardSubSection'

export { default as CardTile } from './CardTile'
