import { gql } from '@apollo/client'
import { useMemo } from 'react'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import type { Branding } from 'd2/queries'
import type { DocumentNode } from 'd2/types'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'

const BRANDING_QUERY: DocumentNode = gql`
  query Branding {
    branding {
      id
      is_whitelabel
      has_open_signup
      dashboard_url
      custom_terms_of_use_url
      name
      logo_url_default: logo_url(variant: DEFAULT)
      logo_url_no_text: logo_url(variant: NO_TEXT)
      logo_url_white_text: logo_url(variant: WHITE_TEXT)
      logo_url_square: logo_url(variant: SQUARE)
      logo_url_sign_in: logo_url(variant: SIGN_IN)
      nav_logo_url
      sign_in_background_image_url
      sign_in_logo_max_width_px
      support_url
      primary_color
      secondary_color
      tertiary_color
      success_color
      error_color
      warning_color
      info_color
      primary_gradient_start
      primary_gradient_end
      secondary_gradient_start
      secondary_gradient_end
      sign_in_additional_image_one_url
      sign_in_additional_image_two_url
      sign_in_additional_image_one_max_width_px
      sign_in_additional_image_two_max_width_px
      sign_in_custom_tagline
      sign_in_template
      sign_in_custom_background_color
      sign_in_gradient_start
      sign_in_gradient_end
      sign_in_carousel_text_color
      sign_in_tagline_text_color
      header_background_color
      header_tab_text_color
      header_tab_text_selected_color
      primary_font_name
      primary_font_fallback
      primary_font_url
      primary_font_family
      secondary_font_name
      secondary_font_fallback
      secondary_font_url
      secondary_font_family
      application_url
      request_invite_text
      is_production
    }
  }
`

const reducer = ({ data }: ReducerArg<Branding>) => data?.branding
export function useBrandingQuery ({
  batchKey,
  skip,
}: {
  batchKey?: string | null,
  skip?: boolean | null
} = {}) {
  return useQuerySwitch<Branding, EO, typeof reducer>(BRANDING_QUERY, useMemo(
    () => ({
      // This is how you're supposed to specify the batchKey for this graphql operation so that it runs in its own batch instead of running along with other batched queries.
      ...batchKey
        ? {
          context: {
            http: {
              batchKey: () => batchKey,
            },
          },
        }
        : null,
      // loadingSwitchHook: useGhostLoadingSwitch,
      reducer,
      skip: !!skip,
    }),
    [batchKey, skip],
  ))
}
