import {
  CheckFeature as CheckFeatureType,
  CheckFeatureVariables,
  Features,
} from 'd2/queries'
import {
  ReducerArg,
  useGhostLoadingSwitch,
  useQuerySwitch,
} from 'd2/hooks/useQuerySwitch'
import { gql } from '@apollo/client'
import { isNil } from 'lodash-es'
import { memo, useMemo } from 'react'
import type { DocumentNode } from 'd2/types'
import type { Props } from './types'

const POLL_INTERVAL: number = 20 * 60 * 1000

const checkFeaturesQuery: DocumentNode = gql`
  query CheckFeature($features: [Features!]!) {
    me {
      id
      has_any_feature(features: $features)
    }
  }
`

const reducer = ({ data }: ReducerArg<CheckFeatureType, CheckFeatureVariables>): boolean | null | undefined => data?.me.has_any_feature
export function useCheckFeatures (features: `${Features}`[], {
  skip = false,
}: {
  skip?: boolean
} = {}): [boolean | null | undefined, React$Element | null] {
  skip = skip || features.length === 0

  const options = useMemo(
    () => ({
      loadingSwitchHook: useGhostLoadingSwitch,
      pollInterval: skip ? 0 : POLL_INTERVAL,
      reducer,
      skip,
      variables: {
        features: features.map((feature) => Features[feature]),
      },
    }),
    // TODO: features is an Array. How to ensure it's memoized and not always a new Array object?
    [features, skip],
  )

  const [hasAnyFeature, querySwitch] = useQuerySwitch<CheckFeatureType, CheckFeatureVariables, typeof reducer>(checkFeaturesQuery, options)

  return [skip ? true : hasAnyFeature, querySwitch]
}

const CheckFeature = memo<Props>(({
  children,
  features,

  // Setting `wait` to `false` will cause the component to render immediately as if the user did not have the feature flag, which in some cases makes for a smoother UI.
  wait = true,
}) => {
  const [hasAnyFeature, querySwitch] = useCheckFeatures(features)

  if (wait && isNil(hasAnyFeature)) return querySwitch

  return children(!!hasAnyFeature)
})

CheckFeature.displayName = 'CheckFeature'

export default CheckFeature
