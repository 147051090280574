import { forEach, isArray } from 'lodash-es'
import { hideSnackbar } from 'd2/actions'
import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Body from 'd2/components/Typography/Body'
import Button from 'd2/components/Button'
import DismissButton from 'd2/components/DismissButton'
import HyperLink from 'd2/components/Typography/HyperLink'
import Snackbar from '@mui/material/Snackbar'
import useStyles from './styles'
import type { Dispatch } from 'd2/actions'
import type { Props } from './types'
import type { State } from 'd2/reducers'

const TIMEOUT_DURATION_IN_MS = 6000

const SnackbarNotification = memo<Props>(() => {
  const { classes, cx } = useStyles()
  const {
    actionPressAction,
    actionTargetBlankLocation,
    actionText,
    d1RedirectLocation,
    d2RedirectLocation,
    hasDismiss,
    heading,
    message,
    snackbarActive,
    type,
  } = useSelector<State, State['snackbar']>(({ snackbar }) => snackbar)

  const dispatch = useDispatch<Dispatch>()

  const handleClickUnconditional = useCallback(() => {
    dispatch(hideSnackbar())
  }, [dispatch])

  const handleClose = useCallback((_event: Event | React.SyntheticEvent<any>) => {
    handleClickUnconditional()
  }, [handleClickUnconditional])

  const handleClick = useCallback(() => {
    if (!actionPressAction) return
    forEach(isArray(actionPressAction) ? actionPressAction : [actionPressAction], dispatch)
  }, [actionPressAction, dispatch])

  return (
    <Snackbar
      anchorOrigin={useMemo(() => ({
        horizontal: 'center',
        vertical: 'bottom',
      }), [])}
      autoHideDuration={TIMEOUT_DURATION_IN_MS}
      classes={useMemo(() => ({
        root: classes.snackbar,
      }), [classes])}
      ContentProps={useMemo(() => ({
        className: classes.content,
        classes: {
          message: classes.messageContainer,
          root: classes.messageRoot,
        },
      }), [classes])}
      message={<>
        <div className={cx(classes.tag, type ? classes[type] : null)} />

        <div
          className={classes.messageWrapper}
        >
          {
            heading && <Body
              className={classes.heading}
              variant='semibold'
            >
              { heading }
            </Body>
          }
          {
            d1RedirectLocation
              ? <HyperLink
                className={classes.message}
                href={d1RedirectLocation}
                onClick={handleClickUnconditional}
              >
                { message }
              </HyperLink>
              : d2RedirectLocation
                ? <HyperLink
                  className={classes.message}
                  onClick={handleClickUnconditional}
                  to={`/d2${d2RedirectLocation}`}
                >
                  { message }
                </HyperLink>
                : <Body
                  className={classes.message}
                  color='primary'
                  variant='light-italic'
                >
                  { message }
                </Body>
          }
        </div>
        <div className={classes.action}>
          { actionText && (actionPressAction
            ? <Button
              onClick={handleClick}
            >
              { actionText }
            </Button>
            : actionTargetBlankLocation
              ? <Button
                href={actionTargetBlankLocation}
                target='_blank'
              >
                { actionText }
              </Button>
              : null) }
          { hasDismiss && <DismissButton
            onDismiss={handleClose}
            variant='textPrimary'
          /> }
        </div>
      </>}
      onClose={handleClose}
      open={snackbarActive}
    />
  )
})

SnackbarNotification.displayName = 'SnackbarNotification'

export default SnackbarNotification
