import { forwardProps } from 'd2/utils/props'
import { memo, useMemo } from 'react'
import Truncate from 'd2/components/Typography/Truncate'
import useStyles from './styles'
import type { Props } from './types'

const MetadataBoxRow = memo<Props>(({
  children,
  className,
  label,
  lyrics,
  mobileTextLeft,
  noTruncate = false,
  showValue = true,
  shrinkOnMobile = true,
  title,
  variant,
  ...props
}) => {
  const { classes, cx } = useStyles({
    mobileTextLeft,
    shrinkOnMobile,
  })
  const maybeTruncated = useMemo(() => (<div
    className={cx(classes.content, {
      [classes.contentNonLyrics]: lyrics !== true,
      [classes.contentLyrics]: lyrics === true,
      [classes.smallPadding]: variant === 'small',
      [classes.padding]: !variant,
    })}
  >
    { children }
  </div>), [
    children,
    classes.content,
    classes.contentLyrics,
    classes.contentNonLyrics,
    classes.padding,
    classes.smallPadding,
    cx,
    lyrics,
    variant,
  ],
  )

  return (
    <div
      className={cx(classes.row, className)}
      {...forwardProps(props)}
    >
      <div
        className={cx(classes.label, {
          [classes.smallPadding]: variant === 'small',
          [classes.noPadding]: variant === 'xsmall',
          [classes.padding]: !variant,
        })}
      >
        { label }
      </div>
      { showValue && (
        noTruncate
          ? maybeTruncated
          : <Truncate title={title ?? children}>
            { maybeTruncated }
          </Truncate>
      ) }
    </div>
  )
})

MetadataBoxRow.displayName = 'MetadataBoxRow'

export default MetadataBoxRow
