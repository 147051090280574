import {
  Col,
  Container,
  Grid,
} from 'd2/components/Grid'
import { SPACING, SPACING_HALF } from 'd2/constants'
import {
  cloneElement,
  isValidElement,
  memo,
  useMemo,
  useState,
} from 'react'
import {
  compact,
  concat,
  fromPairs,
  includes,
  isNumber,
  isString,
  without,
} from 'lodash-es'
import { svgDataImg } from 'd2/svg'
import { track2 } from 'd2/analytics'
import BodySmall from 'd2/components/Typography/BodySmall'
import Checkbox from '@mui/material/Checkbox'
import Heading5 from 'd2/components/Typography/Heading5'
import LoadMoreButton from './LoadMoreButton'
import MetadataSummary from 'd2/components/MetadataSummary'
import Radio from '@mui/material/Radio'
import useCheckboxNormalDefault from 'd2/svg/useCheckboxNormalDefault'
import useCheckboxNormalDisabled from 'd2/svg/useCheckboxNormalDisabled'
import useCheckboxSelectedDefault from 'd2/svg/useCheckboxSelectedDefault'
import useCheckboxSelectedDisabled from 'd2/svg/useCheckboxSelectedDisabled'
import useRadioNormalDefault from 'd2/svg/useRadioNormalDefault'
import useRadioNormalDisabled from 'd2/svg/useRadioNormalDisabled'
import useRadioSelectedDefault from 'd2/svg/useRadioSelectedDefault'
import useRadioSelectedDisabled from 'd2/svg/useRadioSelectedDisabled'
import useStyles from './styles'
import useTranslations from './translations'
import type { Props, SelectedValues } from './types'

const Picker = memo<Props>(({
  alternateOutline,
  anyMoreResults,
  bottomSelect,
  colSizeLg,
  colSizeMd,
  colSizeSm,
  colSizeXl,
  colSizeXs,
  handleLoadMore,
  imageOnly,
  itemClassName,
  items,
  loadMoreLoading,
  loadMoreText,
  multiple,
  onChange,
  prependComponent,
  selectedValues,
  small,
  testID,
  titleFontWeight = 'light',
  truncateSubtitle = true,
  truncateTitle = true,
  verticalStretch,
  withHover = true,
  withLargeIcons,
  withWidescreen,
}) => {
  const _ = useTranslations() // Import translations here to preload locale translations and avoid suspense flicker later.
  const { classes, cx } = useStyles()
  const checkboxValues = useMemo(
    () => fromPairs(
      items.map(({ value }) => [value, includes(selectedValues, value)]),
    ),
    [items, selectedValues],
  )

  const checkboxNormalDefault = useCheckboxNormalDefault()
  const checkboxNormalDisabled = useCheckboxNormalDisabled()
  const checkboxSelectedDefault = useCheckboxSelectedDefault()
  const checkboxSelectedDisabled = useCheckboxSelectedDisabled()
  const radioNormalDefault = useRadioNormalDefault()
  const radioNormalDisabled = useRadioNormalDisabled()
  const radioSelectedDefault = useRadioSelectedDefault()
  const radioSelectedDisabled = useRadioSelectedDisabled()

  const [checkedIcon, icon] = useMemo(
    () => {
      const fileNames: string[] = multiple
        ? [
          checkboxSelectedDefault,
          checkboxNormalDefault,
          checkboxNormalDisabled,
          checkboxSelectedDisabled,
        ]
        : [
          radioSelectedDefault,
          radioNormalDefault,
          radioNormalDisabled,
          radioSelectedDisabled,
        ]

      return fileNames.map((fileName, index: number) => (
        <img
          alt=''
          key={index} // eslint-disable-line react/no-array-index-key
          src={svgDataImg(fileName)}
        />
      ))
    },
    [
      multiple,
      checkboxSelectedDefault,
      checkboxNormalDefault,
      checkboxNormalDisabled,
      checkboxSelectedDisabled,
      radioSelectedDefault,
      radioNormalDefault,
      radioNormalDisabled,
      radioSelectedDisabled,
    ],
  )

  const colProps = useMemo(
    () => ({
      lg: colSizeLg ?? colSizeMd ?? colSizeSm ?? colSizeXs ?? 4,
      md: colSizeMd ?? colSizeSm ?? colSizeXs ?? 4,
      sm: colSizeSm ?? colSizeXs ?? 6,
      xl: colSizeXl ?? colSizeLg ?? colSizeMd ?? colSizeSm ?? colSizeXs ?? 3,
      xs: colSizeXs ?? 12,
    }),
    [
      colSizeLg,
      colSizeMd,
      colSizeSm,
      colSizeXl,
      colSizeXs,
    ],
  )

  const [hovered, setHovered] = useState<string | null | undefined>(null)

  return (
    <Container className={classes.container}>
      <Grid
        alignItems='stretch'
        spacing={small ? SPACING_HALF : withHover ? SPACING : undefined}
      >
        { prependComponent && (
          <Col
            {...colProps}
          >
            { prependComponent }
          </Col>
        ) }
        { items.map(({
          appendComponent,
          enabled = true,
          highlightBackground,
          icon: itemIcon,
          iconUrl: itemIconUrl,
          name,
          onDoubleSelect,
          subText,
          testID: itemTestID,
          value,
        }) => (
          <Col
            key={String(value)}
            {...colProps}
            className={itemClassName}
          >
            <MetadataSummary
              contain={bottomSelect}
              containerClassName={cx({
                [classes.pickerItemSmall]: small,
                [classes.disabled]: !enabled,
                [classes.notSelected]: !alternateOutline && !includes(selectedValues, value),
                [classes.selected]: !alternateOutline && includes(selectedValues, value),
                [classes.metadataContainerBottomSelect]: bottomSelect,
                [classes.hover]: withHover,
                [classes.hoverSelected]: withHover && includes(selectedValues, value),
                [classes.alternateOutline]: alternateOutline,
                [classes.highlightBackground]: highlightBackground,
              })}
              containerComponent='label'
              image={
                (
                  // @ts-expect-error Property 'type' does not exist on type 'string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | React$Element<...>'. Property 'type' does not exist on type 'string'.ts(2339)
                  itemIcon?.type?.displayName === 'GradientIcon'
                )
                && withHover
                && itemIcon
                && isValidElement(itemIcon)
                && !isString(itemIcon)
                && !isNumber(itemIcon)
                  ? cloneElement(itemIcon, {
                    // @ts-expect-error TS version 4.8 started complaining about cloneElement's props.
                    animate: true,
                    forceBackground: true,
                    hovering: (hovered && hovered === value) || includes(selectedValues, value),
                  })
                  : itemIcon
              }
              imageClassName={cx({
                [classes.imageLarge]: !bottomSelect && withLargeIcons && !withWidescreen,
                [classes.image]: !bottomSelect && !withWidescreen,
                [classes.imageBottomSelect]: bottomSelect,
              })}
              imageOnly={imageOnly}
              imageUrl={itemIconUrl}
              inPicker
              onMouseEnter={withHover && itemIcon
                ? () => {
                  setHovered(value)
                }
                : null}
              onMouseLeave={withHover && itemIcon
                ? () => {
                  setHovered(null)
                }
                : null}
              subText={typeof subText === 'function' ? subText(selectedValues) : subText}
              testID={itemTestID ? `MetadataSummary-${itemTestID}${highlightBackground ? '-highlighted' : ''}` : ''}
              textClassName={cx(classes.label, { [classes.labelSmall]: small })}
              title={name && (small
                ? <BodySmall>
                  { name }
                </BodySmall>
                : <Heading5 variant={titleFontWeight}>
                  { name }
                </Heading5>)}
              truncateSubtitle={truncateSubtitle}
              truncateTitle={truncateTitle}
              verticalStretch={verticalStretch}
              withHover={withHover}
              withWidescreen={withWidescreen}
              {
                ...onDoubleSelect
                  ? {
                    onClick: (event) => {
                      // TODO: Remove 'as' type assertions because they are unsafe.
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                      if ((event.target as HTMLElement).textContent === name && includes(selectedValues, value)) {
                        onDoubleSelect()
                      }
                    },
                  }
                  : {}
              }
            >
              { enabled && (
                <div className={cx(classes.checkboxWrapper, { [classes.checkboxWrapperBottomSelect]: bottomSelect })}>
                  { multiple
                    ? (
                      <Checkbox
                        checked={checkboxValues[value]}
                        checkedIcon={checkedIcon}
                        classes={{ root: classes.icon }}
                        data-test-id={itemTestID || (testID && `${testID}-${value}`)}
                        icon={icon}
                        inputProps={{
                          value,
                        }}
                        onChange={(_event, checked) => { // eslint-disable-line react-memo/require-usememo
                          const newSelectedValues: SelectedValues = compact(checked
                            ? concat(selectedValues, value)
                            : without(selectedValues, value))
                          track2('picker_changed', {
                            new_values: newSelectedValues,
                            picker_type: 'checkbox',
                            previous_values: selectedValues,
                            test_id: testID,
                            value,
                          })
                          onChange?.(newSelectedValues)
                        }}
                      />
                    )
                    : (
                      <Radio
                        checked={checkboxValues[value]}
                        checkedIcon={checkedIcon}
                        classes={{ root: classes.icon }}
                        data-test-id={itemTestID || (testID && `${testID}-${value}`)}
                        icon={icon}
                        inputProps={{
                          value,
                        }}
                        onChange={(_event, checked) => { // eslint-disable-line react-memo/require-usememo
                          if (checked) {
                            onChange?.([value])
                          }
                          track2('picker_changed', {
                            new_values: checked ? [value] : [],
                            picker_type: 'radio',
                            previous_values: selectedValues,
                            test_id: testID,
                            value,
                          })
                        }}
                      />
                    ) }
                </div>
              ) }
            </MetadataSummary>
            { appendComponent?.({ selected: !!checkboxValues[value] }) }
          </Col>
        )) }
        { handleLoadMore && (
          <Col>
            <LoadMoreButton
              anyMoreResults={anyMoreResults}
              handleLoadMore={handleLoadMore}
              loadMoreLoading={loadMoreLoading}
              loadMoreText={loadMoreText}
            />
          </Col>
        ) }

      </Grid>
    </Container>
  )
})

Picker.displayName = 'Picker'

export default Picker
