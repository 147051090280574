/*
Adds a label and help text to the child input control.
This is intended for use by various form field components. It is not intended to be used in screens.
*/
import { memo, useMemo } from 'react'
import FaIcon from 'd2/components/FaIcon'
import Flexbox from 'd2/components/Layout/Flexbox'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import Tooltip from 'd2/components/Tooltip'
import useStyles from './styles'
import type { Props } from './types'

const InputContainer = memo<Props>(({
  boldLabel,
  children,
  className,
  disabled,
  error,
  errorPlacement = 'helpText',
  fullWidth,
  helperText,
  id,
  label,
  labelClassName,
  labelPosition = 'top',
  renderError,
  required,
  tooltipText,
  topRightElement,
  verticalAlignTop,
}) => {
  const { classes, cx } = useStyles()

  const helpText: React$Node | null | undefined = !!error && errorPlacement === 'helpText'
    ? renderError ? renderError(error) : error
    : helperText
  const useErrorTooltip: boolean = !!error && errorPlacement === 'tooltip'
  const labelHtmlFor = id ? { htmlFor: id } : {}
  return (
    <FormControl
      classes={useMemo(() => ({
        root: verticalAlignTop ? undefined : classes.formControlRoot,
      }), [classes.formControlRoot, verticalAlignTop])}
      className={className}
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      required={required}
    >
      <div
        className={cx({
          [classes.containerForLeftLabel]: labelPosition === 'left',
          [classes.containerForRightLabel]: labelPosition === 'right',
          [classes.containerForTopLabel]: labelPosition === 'top',
          [classes.containerForTopCenterLabel]: labelPosition === 'topCenter',
        })}
      >
        {
          labelPosition === 'topOrPlaceholder'
            ? tooltipText
              ? <Flexbox secondaryAlign='center'>
                <InputLabel
                  className={classes.labelTopOrPlaceholder}
                  disabled={disabled}
                  error={!!error}
                  variant='standard'
                  {...labelHtmlFor}
                >
                  { label }
                </InputLabel>
                <Tooltip
                  hasArrow
                  title={tooltipText}
                >
                  <span
                    className={classes.tooltip}
                  >
                    <FaIcon
                      icon='info-circle'
                      weight='solid'
                    />
                  </span>
                </Tooltip>
              </Flexbox>
              : (
                <InputLabel
                  className={classes.labelTopOrPlaceholder}
                  disabled={disabled}
                  error={!!error}
                  variant='standard'
                  {...labelHtmlFor}
                >
                  { label }
                </InputLabel>
              )
            : tooltipText
              ? <Flexbox align='spaceBetween'>
                <Flexbox secondaryAlign='center'>
                  <FormLabel
                    classes={{
                      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                      root: cx(labelPosition === 'left' || labelPosition === 'right' ? boldLabel ? classes.labelHorizontalBold : classes.labelHorizontal : boldLabel ? classes.labelTopBold : classes.labelTop, labelClassName || null),
                    }}
                    disabled={disabled}
                    error={!!error}
                    {...labelHtmlFor}
                  >
                    { label }
                  </FormLabel>
                  <Tooltip
                    hasArrow
                    title={tooltipText}
                  >
                    <span
                      className={classes.tooltip}
                    >
                      <FaIcon
                        icon='info-circle'
                        weight='solid'
                      />
                    </span>
                  </Tooltip>
                  { labelPosition === 'top' && topRightElement }
                </Flexbox>
              </Flexbox>
              : (
                <Flexbox align='spaceBetween'>
                  <FormLabel
                    classes={{
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                      root: cx(labelPosition === 'left' || labelPosition === 'right' ? boldLabel ? classes.labelHorizontalBold : classes.labelHorizontal : boldLabel ? classes.labelTopBold : classes.labelTop, labelClassName || null),
                    }}
                    disabled={disabled}
                    error={!!error}
                    {...labelHtmlFor}
                  >
                    { label }
                  </FormLabel>
                  { labelPosition === 'top' && topRightElement }
                </Flexbox>
              )
        }
        { useErrorTooltip
          ? (
            (<Tooltip
              title={String(error)}
              type='error'
            >
              { /* TODO: Remove 'as' type assertions because they are unsafe. */ }
              { /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */ }
              { children as React$Element }
            </Tooltip>)
          )
          : children }
      </div>
      { helpText && (<div>
        <FormHelperText
          disabled={disabled}
          error={!!error}
          variant='standard'
        >
          { helpText }
        </FormHelperText>
      </div>) }
    </FormControl>
  )
})

InputContainer.displayName = 'InputContainer'

export default InputContainer
