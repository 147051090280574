import { AnimatePresence, m } from 'framer-motion'
import {
  Col,
  Grid,
  GridContainer,
} from 'd2/components/Grid'
import { MAX } from 'd2/constants'
import { forwardProps } from 'd2/utils/props'
import { isNil, isString } from 'lodash-es'
import { memo, useCallback } from 'react'
import Body from 'd2/components/Typography/Body'
import BodyExtraSmall from 'd2/components/Typography/BodyExtraSmall'
import Box from 'd2/components/Box'
import Divider from 'd2/components/Divider'
import FaIcon from 'd2/components/FaIcon'
import Flexbox from 'd2/components/Layout/Flexbox'
import Heading3 from 'd2/components/Typography/Heading3'
import HyperLink from 'd2/components/Typography/HyperLink'
import Overlay from 'd2/components/Overlay'
import ScrollableContainer from 'd2/components/ScrollableContainer'
import useModal from 'd2/hooks/useModal'
import useStyles from './styles'
import type { Alignment, Props } from './types'

const CENTER: Alignment = 'center'
const LEFT: Alignment = 'left'
const RIGHT: Alignment = 'right'
export const MODAL_WIDTH = 600

const defaultVariants = (maxWidth: number | undefined) => ({
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
    x: maxWidth,
  },
  initial: {
    opacity: 0,
    x: maxWidth,
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.15,
      ease: 'easeOut',
    },
    x: 0,
  },
})

const SideModal = memo<Props>(({
  alignButton = CENTER,
  alignContent = CENTER,
  alignHeading = CENTER,
  alpha,
  bottomButton,
  children,
  content,
  divider,
  footNote,
  footNoteItalic = true,
  isOpen,
  heading,
  id,
  maxWidthPx,
  modalIndex,
  modalKey,
  onToggleClose,
  overlayRootProps,
  subHeading,
  testID,
  variants,
  ...props
}) => {
  const { classes, cx } = useStyles()

  const {
    isOpen: useModalIsOpen,
    modalId,
  } = useModal({
    modalIndex,
    modalKey,
  })

  const onClickAway = useCallback((_event: any) => {
    onToggleClose?.(false)
  }, [onToggleClose])

  const onClick = useCallback(() => onToggleClose?.(false), [onToggleClose])

  return (
    <AnimatePresence mode='wait'>
      { (isNil(isOpen) ? !useModalIsOpen && modalKey : !isOpen)
        ? null
        : (
          <Overlay
            alpha={alpha}
            innerHeight={MAX}
            light
            onClickAway={onClickAway}
            rootProps={overlayRootProps}
          >
            <ScrollableContainer fillHeight>
              <GridContainer
                className={classes.modalWrapper}
                noPadding
                testID={modalId}
              >
                <Grid
                  fitViewportHeight
                  justifyContent='flex-end'
                >
                  <Col
                    className='modal-clickable-area'
                    component={m.div}
                    style={{ maxWidth: `${maxWidthPx ?? MODAL_WIDTH}px` }}
                    {...forwardProps(props)}
                  >
                    <m.div
                      animate='open'
                      className={classes.modalContainer}
                      exit='exit'
                      id={id}
                      initial='initial'
                      variants={variants || defaultVariants(maxWidthPx)}
                    >
                      <Flexbox align='center'>
                        { onToggleClose && (
                          <HyperLink
                            noFont
                            onClick={onClick}
                            testID={testID && `${testID}-HyperLink-close`}
                          >
                            <FaIcon
                              className={classes.closeButton}
                              icon='times'
                              testID='closeButton'
                              weight='light'
                            />
                          </HyperLink>
                        ) }
                        { heading && (
                          <div
                            className={cx(classes.headingWrapper, {
                              [classes.alignLeft]: alignHeading === LEFT,
                              [classes.alignCenter]: alignHeading === CENTER,
                            })}
                          >
                            <Heading3 color='darkGray'>
                              { heading }
                            </Heading3>
                            { divider && <Divider
                              halfMarginTop
                              noMarginBottom
                            /> }
                          </div>
                        ) }
                      </Flexbox>
                      { children && (
                        <div className={classes.childrenWrapper}>
                          { isString(children)
                            ? <Body>
                              { children }
                            </Body>
                            : children }
                        </div>) }
                      {
                        content && (
                          <div
                            className={cx(classes.content, {
                              [classes.alignLeft]: alignContent === LEFT,
                              [classes.alignCenter]: alignContent === CENTER,
                            })}
                          >
                            {
                              subHeading
                                && <Flexbox align='center'>
                                  <Box my={1}>
                                    <Body>
                                      { subHeading }
                                    </Body>
                                  </Box>
                                </Flexbox>
                            }
                            { content }
                          </div>
                        )
                      }

                      { footNote && (
                        <div
                          className={cx(classes.footNote, {
                            [classes.alignLeft]: alignContent === LEFT,
                            [classes.alignCenter]: alignContent === CENTER,
                          })}
                        >
                          <BodyExtraSmall
                            color='gray'
                            variant={footNoteItalic ? 'italic' : 'normal'}
                          >
                            { footNote }
                          </BodyExtraSmall>
                        </div>
                      ) }
                      {
                        bottomButton && (
                          <div
                            className={cx(classes.bottomButton, {
                              [classes.alignLeft]: alignButton === LEFT,
                              [classes.alignCenter]: alignButton === CENTER,
                              [classes.alignRight]: alignButton === RIGHT,
                            })}
                          >
                            { bottomButton }
                          </div>
                        )
                      }
                    </m.div>
                  </Col>
                </Grid>
              </GridContainer>
            </ScrollableContainer>
          </Overlay>
        ) }
    </AnimatePresence>
  )
})

SideModal.displayName = 'SideModal'

export default SideModal
