import { omit } from 'lodash-es'
import { track2 } from 'd2/analytics'
import type { Exact } from 'd2/types'
import type { GenericReducer } from '../types'

// TODO: How to use an array instead? The values do nothing here.
export const MODAL_KEYS = Object.freeze({
  AdditionalServicesBannerModal: undefined,
  AddressChangeModal: undefined,
  AffidavitModal: undefined,
  AlbumDetailsTerritories: undefined,
  AlbumTracks: undefined,
  AlbumsListModal: undefined,
  AnalyticsFilterModal: undefined,
  AnalyticsReportModal: undefined,
  ArchivePayee: undefined,
  ArchivePayeeAssetSplits: undefined,
  AvailableNetworksModal: undefined,
  BasicFilterModal: undefined,
  ComposerModal: undefined,
  CompositionConfirmationModal: undefined,
  ConfirmOrganizationUserInviteModal: undefined,
  ConflictCountries: undefined,
  ConnectSocial: undefined,
  ConnectionSettings: undefined,
  CreateArtistModal: undefined,
  CreatePayee: undefined,
  CreatePayeeModal: undefined,
  CreateRelease: undefined,
  DateFilterModal: undefined,
  DestinationNetworks: undefined,
  GrossEarningsLearnMore: undefined,
  InstagramInfo: undefined,
  LifetimeEarningsLearnMore: undefined,
  LifetimeHistoryModal: undefined,
  MetadataSearchModal: undefined,
  MissingSoundRecordingRightsModal: undefined,
  MonthlySendReportModal: undefined,
  NetLabelEarningsLearnMore: undefined,
  NetworkExtraMoreInfo: undefined,
  OneTimePasswordModal: undefined,
  OtpSecretModal: undefined,
  OverviewFilterSideModal: undefined,
  PasswordUpdateModal: undefined,
  PayorFtuiModal: undefined,
  PciComplianceFaq: undefined,
  PremiumPartnerArtistSelectModal: undefined,
  RecoupmentConfirmation: undefined,
  RecoupmentItemsModal: undefined,
  ReleaseCalendarFilterModal: undefined,
  ReleaseDetailsModal: undefined,
  RevenueFilterModal: undefined,
  SendReportModal: undefined,
  SignatureCreation: undefined,
  SmartlinksAddModal: undefined,
  SmartlinksEditModal: undefined,
  StorageTier: undefined,
  StorageTierArtist: undefined,
  StorageTierDowngrade: undefined,
  SuccessModal: undefined,
  SwitchUmbrellaLinkedUserModal: undefined,
  TemporarilyLocked: undefined,
  TipaltiFtuiModal: undefined,
  TipaltiVerification: undefined,
  TrackMetadataModal: undefined,
  UnarchivePayee: undefined,
  UpdateAlbumMetadataConfirmationModal: undefined,
  UpdateMetadataConfirmationModal: undefined,
  VideoUploaderFtuiModal: undefined,
})

export type ModalState = Exact<{
  [x: string]: boolean | number | string | null | null | undefined
}>

export type ModalKey = keyof typeof MODAL_KEYS

export type State = Exact<{
  [k in ModalKey]?: ModalState;
}>

const init: () => State = () => ({})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SHOW_MODAL': {
    track2('show_modal', { modal_key: action.payload.key })
    return {
      ...state,
      [action.payload.key]: action.payload.state,
    }
  }
  case 'HIDE_MODAL': {
    track2('hide_modal', { modal_key: action.payload.key })
    return omit(state, action.payload.key)
  }
  default: {
    return state
  }
  }
}

export default reducer
