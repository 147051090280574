import { ApolloProvider } from '@apollo/client'
import { CacheProvider } from '@emotion/react'
import { Fragment } from 'react'
import { LazyMotion } from 'framer-motion'
import { Provider } from 'react-redux'
import { HistoryRouter as Router } from 'redux-first-history/rr6' // eslint-disable-line import/no-internal-modules
import { ThemeProvider } from '../ThemeProvider'
import ApplicationErrorBoundary from '../ApplicationErrorBoundary'
import AssetVersionProvider from '../AssetVersionProvider'
import CartProvider from 'd2/providers/CartProvider'
import ModalProvider from '../ModalProvider'
import ResponsiveProvider from '../ResponsiveProvider'
import SessionNoticesShowSnackbars from '../SessionNoticesShowSnackbars'
import createCache, { EmotionCache } from '@emotion/cache'
import type { Props, Type } from './types'

const loadFeatures = () => import('./framer_motion_features').then((res) => res.default)

let muiCache: EmotionCache | undefined

export const createMuiCache = () => {
  muiCache = createCache({
    key: 'mui',
    prepend: true,
  })

  return muiCache
}

// TODO: GraphQL Error Boundary https://medium.freecodecamp.org/how-to-handle-graphql-errors-with-react-error-boundaries-dd9273feda85
// TODO: React.memo
const BaseProvider: Type = ({
  apolloClient,
  children,
  generateClassName,
  history,
  includeErrorBoundary = true,
  isTddRoot,
  store,
}: Props) => {
  const MaybeErrorBoundary = includeErrorBoundary ? ApplicationErrorBoundary : Fragment

  return (
    <MaybeErrorBoundary>
      <LazyMotion
        features={loadFeatures}
        strict
      >
        <Provider store={store}>
          <ApolloProvider client={apolloClient}>
            <Router history={history}>
              <ResponsiveProvider>
                <CacheProvider value={muiCache ?? createMuiCache()}>
                  <ThemeProvider
                    generateClassName={generateClassName}
                    isTddRoot={isTddRoot}
                  >
                    <ModalProvider>
                      <AssetVersionProvider>
                        <SessionNoticesShowSnackbars isTddRoot={isTddRoot} />
                        <CartProvider>
                          { children ?? null }
                        </CartProvider>
                      </AssetVersionProvider>
                    </ModalProvider>
                  </ThemeProvider>
                </CacheProvider>
              </ResponsiveProvider>
            </Router>
          </ApolloProvider>
        </Provider>
      </LazyMotion>
    </MaybeErrorBoundary>
  )
}

export default BaseProvider
