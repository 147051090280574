import { coerceString, useUrlQueryParam } from 'd2/hooks/useUrlQueryParam'

const useReturnUrl = () => {
  const [returnUrl] = useUrlQueryParam('return_url', coerceString)

  // Prevent user from getting redirected outside our site
  // This regex checks if the URL is more than just a path (ex. includes http, www, domain name)
  // Pass: '/d2/files/audio/albums'
  // Fail: 'http://www.shady-website.ru' or 'evil.co/do/not/click'
  if (/[\w#%()+./:=?@~]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/.test(returnUrl)) {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    params.set('return_url', '')
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`)

    return undefined
  }

  return returnUrl
}

export default useReturnUrl
