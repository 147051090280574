/* eslint-disable react-memo/require-memo, react-memo/require-usememo, @typescript-eslint/no-shadow */
import {
  Children,
  MouseEvent,
  isValidElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Col, Grid } from 'd2/components/Grid'
import { SortParams } from 'd2/types'
import { columnNameReplacer } from 'd2/components/AdvancedSearch/utils'
import {
  compact,
  filter,
  flatten,
  head,
  isBoolean,
  isEmpty,
  isNil,
  isString,
  some,
  sortBy,
  toString,
  uniq,
} from 'lodash-es'
import { d } from 'd2/utils'
import { onlyText } from 'react-children-utilities'
import { useIsMobile } from 'd2/components/Responsive'
import Body from 'd2/components/Typography/Body'
import BodySmall from 'd2/components/Typography/BodySmall'
import Box from 'd2/components/Box'
import Divider from 'd2/components/Divider'
import Flexbox from 'd2/components/Layout/Flexbox'
/* eslint-disable consistent-default-export-name/default-import-match-filename */
import { SPACING_HALF } from 'd2/constants'
import { px } from 'd2/utils/style'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MaterialTable from '@mui/material/Table'
import MaterialTableBody from '@mui/material/TableBody'
import MaterialTableCell from '@mui/material/TableCell'
import MaterialTableHead from '@mui/material/TableHead'
import MaterialTablePagination from '@mui/material/TablePagination'
import MaterialTableRow from '@mui/material/TableRow'
import MetadataBoxRow from 'd2/components/MetadataBoxRow'
import Select from 'd2/components/Select'
import TablePaginationActions from 'd2/components/Table/TablePaginationActions'
import TableSortLabel from './TableSortLabel'
import cx from 'classnames'
import useStyles, { borderStyles } from './styles'
import useTheme from 'd2/hooks/useTheme'
import useTranslations from './translations'
import type { DefaultPaginationSortProps, PaginationProps } from 'd2/hooks/useUrlPagination/types'
import type {
  ExtraRowCellClonedComponentType,
  ExtraRowCellComponentType,
  ExtraRowComponentType,
  GroupedChildren,
  RowBannerComponentType,
  RowCellClonedComponentType,
  RowCellCommonProps,
  RowCellComponentType,
  SharedComponentsChildrenFunctionProp,
  SharedComponentsType,
  SubRowCellComponentType,
  TableComponentType,
  TablePaginationReact$ComponentType,
} from './types'
import type { OptionsType } from 'd2/components/Select/types'

const DEFAULT_ROWS_PER_PAGE_OPTIONS: number[] = [
  5,
  10,
  20,
  50,
]
const SELECTION_CELL_WIDTH = 40

// This never actually renders, just gets cloned.
export const RowCell: RowCellComponentType<any, any, any> = () => <></>
export const SubRowCell: SubRowCellComponentType<any> = () => <></>
export const ExtraRowCell: ExtraRowCellComponentType = () => <></>
export const ExtraRow: ExtraRowComponentType = () => <></>
export const SubRow: ExtraRowComponentType = () => <></>

const RowCellCloned: RowCellClonedComponentType<any> = ({
  align,
  children,
  className,
  maxWidth,
  noBottomBorder,
  smallerPadding,
  stickyScroll = false,
  width,
}) => {
  const { classes } = useStyles({
    maxWidth,
    stickyScroll,
    width,
  })

  return (
    <MaterialTableCell
      align={align ?? 'center'}
      className={cx(
        className,
        classes.rowCell,
        {
          [classes.smallPadding]: !smallerPadding,
          [classes.smallerPadding]: smallerPadding,
          [classes.noBottomBorder]: noBottomBorder,
        },
      )}
      sx={{
        width: width && `${width}%`,
      }}
    >
      { children }
    </MaterialTableCell>
  )
}

const ExtraRowCellCloned: ExtraRowCellClonedComponentType = ({
  align,
  children,
  colSpan,
}) => {
  const { classes } = useStyles({})

  return (
    <MaterialTableCell
      align={align ?? 'center'}
      className={`${classes.rowCell} ${classes.noBottomBorder} ${classes.smallPadding}`}
      colSpan={colSpan}
    >
      { children }
    </MaterialTableCell>
  )
}

// This never actually renders, just gets cloned.
export const SharedComponents: SharedComponentsType<any, any> = () => <></>

const RowCellMetadataCloned: RowCellClonedComponentType<any> = ({
  children,
  label,
}) => (<Box py={1}>
  <Flexbox
    secondaryAlign='center'
    vertical
  >
    { isString(label)
      ? <Body>
        { label }
      </Body>
      : label }
    { children }
  </Flexbox>
</Box>)

const RowCellMetadataBoxRowCloned: RowCellClonedComponentType<any> = ({
  children,
  label,
}) => {
  const { classes } = useStyles({})

  return (
    <MetadataBoxRow
      className={`${classes.noBorderBottomLast} ${classes.smallPadding}`}
      label={label}
      noTruncate
      shrinkOnMobile={false}
      variant='xsmall'
    >
      { children }
    </MetadataBoxRow>
  )
}

// This never actually renders, just gets cloned.
export const RowBanner: RowBannerComponentType<any, any> = () => <></>

export const TablePagination: TablePaginationReact$ComponentType<any> = ({
  footerNoBorder,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  totalRowCount,
  useTablePagination,
}) => {
  const { onChangePerPage, onPageChange, page, perPage } = useTablePagination()
  const t = useTranslations()
  const isMobile = useIsMobile()
  const { classes, cx } = useStyles({})
  const ref = useRef<HTMLElement>(null)

  if (!totalRowCount) return null

  // const handleScrollChange = () => {
  //   if (ref.current) {
  //     window.scrollTo({
  //       behavior: 'auto',
  //       top: (ref.current.parentNode as HTMLElement | undefined)?.offsetTop ?? 300 - 300,
  //     })
  //   }
  // }

  const rowsPerPageOptionsIncludingCurrent: number[] = perPage ? sortBy(uniq([...rowsPerPageOptions, perPage])) : rowsPerPageOptions
  const remainingOptions: number[] = filter<number>(
    rowsPerPageOptionsIncludingCurrent,
    (option: number, index: number) => option < totalRowCount || (index > 0 && (rowsPerPageOptionsIncludingCurrent[index - 1] ?? 0) < totalRowCount),
  )

  if (totalRowCount <= (head(rowsPerPageOptions) ?? 0)) return null

  return (<MaterialTablePagination
    ActionsComponent={({
      // @ts-expect-error Property 'testID' does not exist on type 'TablePaginationActionsProps & { children?: ReactNode; }'.ts(2339)
      testID,
      ...props
    }) => (
      <TablePaginationActions
        buttonClassName={classes.pagingButton}
        data-test-id={testID}
        {...props}
      />
    )}
    classes={{
      actions: cx({ [classes.mobilePaginationActions]: isMobile }),
      input: cx({ [classes.hidden]: isMobile }),
      root: cx({ [classes.footerNoBorder]: !!footerNoBorder }),
      toolbar: cx({ [classes.mobilePaginationToolbar]: isMobile }),
    }}
    count={totalRowCount}
    labelRowsPerPage={isMobile ? null : t.rowsPerPage}
    onPageChange={(_event: MouseEvent | null, page: number): void => {
      onPageChange(page)
    }}
    onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      onChangePerPage(Number(event.target.value))
    }}
    page={page}
    ref={ref}
    rowsPerPage={perPage}
    rowsPerPageOptions={remainingOptions}
  />)
}

// Table Sorter to be used only on mobile designs
export type TableSorterProps<T extends DefaultPaginationSortProps = DefaultPaginationSortProps> = {
  sortOptions: OptionsType,
  useTablePagination: () => PaginationProps<T>
}

const TableSorter: React$StatelessFunctionalComponent<TableSorterProps<any>> = ({
  sortOptions,
  useTablePagination,
}) => {
  const theme = useTheme()
  const isMobile: boolean = useIsMobile()
  const t = useTranslations()
  const SortBodyTag = isMobile ? BodySmall : Body
  const {
    onChangeSortBy,
    sortBy,
    sortDirection,
  } = useTablePagination()

  return (
    <Grid justifyContent='center'>
      <Col xs={3}>
        <Box
          py={isMobile ? 2 : 1}
        >
          <SortBodyTag>
            { t.sortBy }
          </SortBodyTag>
        </Box>
      </Col>
      <Col xs={9}>
        <Select
          borderStyling={borderStyles(theme)}
          indicatorStyling={{
            color: theme.branding?.primaryColor,
          }}
          minWidth={200}
          onChange={useCallback((value) => {
            const params = SortParams.parse(JSON.parse(toString(value)))
            onChangeSortBy(params.sortBy, params.sortDirection)
          }, [onChangeSortBy])}
          options={sortOptions}
          value={useMemo(() => JSON.stringify({
            sortBy,
            sortDirection,
          }), [sortBy, sortDirection])}
        />
      </Col>
    </Grid>
  )
}

export const Table: TableComponentType<any, any, any, any, any> = ({
  borderTop,
  borderlessCells,
  children,
  data,
  disableDefaultPagination,
  footerNoBorder,
  getSubRows,
  maxHeight,
  minWidth,
  pagination = true,
  renderHead = true,
  rowClassName,
  sortIconBefore,
  stickyHeader = false,
  stickyScroll = false,
  testID = 'TableV2',
  tooltipPlacement,
  totalRowCount,
  useTablePagination,
}) => {
  const isMobile = useIsMobile()
  const [collapsed, setCollapsed] = useState<{ [key: number]: boolean }>({})

  const groupedChildren = useMemo(() => {
    const groupedChildren: GroupedChildren<any, any, any> = {
      ExtraRow: [],
      RowBanner: [],
      RowCell: [],
      SharedComponents: [],
      SubRow: [],
    }

    Children.forEach(children, (child) => {
      if (child && !isNil(child) && isValidElement(child) && child.type && !isBoolean(child)) {
        switch (child.type) {
        case ExtraRow: {
          groupedChildren.ExtraRow.push(
            // @ts-expect-error Type '(b: any, a: any) => ReactNode' is not assignable to type 'React$Element<ExtraRowCellComponentType>[]'.
            child)
          break
        }
        case RowCell: {
          groupedChildren.RowCell.push(
            // @ts-expect-error Type '(b: any, a: any) => ReactNode' is not assignable to type 'React$Element<ExtraRowCellComponentType>[]'.
            child)
          break
        }
        case RowBanner: {
          groupedChildren.RowBanner.push(
            // @ts-expect-error Type '(b: any, a: any) => ReactNode' is not assignable to type 'React$Element<ExtraRowCellComponentType>[]'.
            child)
          break
        }
        case SharedComponents: {
          groupedChildren.SharedComponents.push(
            // @ts-expect-error Type '(b: any, a: any) => ReactNode' is not assignable to type 'React$Element<ExtraRowCellComponentType>[]'.
            child)
          break
        }
        case SubRow: {
          groupedChildren.SubRow.push(
            // @ts-expect-error Type '(b: any, a: any) => ReactNode' is not assignable to type 'React$Element<ExtraRowCellComponentType>[]'.
            child)
          break
        }
        default: {
          break
        }
        }
      }
    })

    return groupedChildren
  }, [children])

  const { classes, cx } = useStyles({
    maxHeight,
    minWidth,
    stickyScroll,
  })
  const mobileCells = filter(groupedChildren.RowCell, ({ props: { mobile } }: { props: RowCellCommonProps<any> }) => mobile)
  if (groupedChildren.RowBanner.length > 1) throw new Error(`<Table> maybe have at most 1 <RowBanner> child. Got: ${String(groupedChildren.RowBanner.length)}`)
  if (groupedChildren.SharedComponents.length > 1) throw new Error(`<Table> maybe have at most 1 <SharedComponents> child. Got: ${String(groupedChildren.SharedComponents.length)}`)

  const makeSharedComponents: SharedComponentsChildrenFunctionProp<any, {
    [x: string]: React$ComponentType<any>
  // @ts-expect-error TODO: Fix: Property 'children' does not exist on type 'SharedComponentsType<any, any>'.ts(2339)
  }> | null | undefined = groupedChildren.SharedComponents[0]?.props?.children
  // NOTE: It is VERY important that SharedComponents.props.children function is memoized with useCallback!
  // Otherwise we will make new Component functions every render (for every row).
  const sharedComponentsByDataRowIndex = useMemo(() => {
    if (!makeSharedComponents) return

    return data.map((data) => makeSharedComponents(data))
  }, [data, makeSharedComponents])

  const RowBannerChildEl: React$Element<RowBannerComponentType<any, any>> | null | undefined = groupedChildren.RowBanner[0]
  // @ts-expect-error TODO: Fix: Property 'tableMode' does not exist on type 'RowBannerComponentType<any, any>'.ts(2339)
  const mobileTableMode: 'horizontal' | 'vertical' = RowBannerChildEl?.props.tableMode ?? 'vertical'
  const RowCellMetadataClonedComponent = mobileTableMode === 'vertical' ? RowCellMetadataBoxRowCloned : RowCellMetadataCloned
  const {
    onChangeSortBy,
    sortBy,
    sortDirection,
  } = d(useTablePagination?.())

  const handleExpandRow = useCallback((dataRowIndex: number) => {
    setCollapsed((prevCollapsed) => ({
      ...prevCollapsed,
      [dataRowIndex]: !prevCollapsed[dataRowIndex],
    }))
  }, [])

  const renderSubRows = useCallback((dataRow: ComponentProps<typeof Table>['data'], dataRowIndex: number) => {
    const subRowData = getSubRows ? getSubRows(dataRow) : null
    const rowCollapsed = collapsed[dataRowIndex]

    if (!subRowData) return null

    return (<MaterialTableRow
      key={`subrow-${dataRowIndex}`}
    >
      <MaterialTableCell
        colSpan={12}
        key={`subrow-${dataRowIndex}`}
        style={{
          border: 'none',
          padding: 0,
        }}
      >
        <Collapse
          in={rowCollapsed}
        >
          <div className={classes.tableWrapper}>
            { /* @ts-expect-error TODO: Fix: small not valid prop. Maybe MUI types version issue? */ }
            <MaterialTable
              className={classes.subTable}
              small={isMobile}
              style={stickyScroll ? { tableLayout: 'fixed' } : undefined}
            >
              {
                subRowData.map((dataSubRow, dataSubRowIndex) =>
                  groupedChildren.SubRow.map(({
                    props: {
                      // @ts-expect-error Property 'children' does not exist on type 'ExtraRowComponentType'.ts(2339)
                      children,
                    },
                  }) => (
                    <MaterialTableRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={dataSubRowIndex}
                    >
                      {
                        Children.toArray(children).map(({
                          // @ts-expect-error Property 'props' does not exist on type 'ReactChild | ReactFragment | ReactPortal'.ts(2339)
                          props:
                          { children: cellChildren, ...rest },
                        }, index: number) => (
                          <RowCellCloned
                            key={`${index}-${dataSubRowIndex}`} // eslint-disable-line react/no-array-index-key
                            stickyScroll={stickyScroll}
                            style={{
                              paddingLeft: px(SPACING_HALF),
                              paddingRight: px(SPACING_HALF),
                            }}
                            {...rest}
                          >
                            { cellChildren(dataSubRow, sharedComponentsByDataRowIndex ? sharedComponentsByDataRowIndex[dataRowIndex] : undefined) }
                          </RowCellCloned>
                        ))
                      }
                      <MaterialTableCell
                        className={cx(classes.tableCellChevron)}
                        padding='none'
                        width={px(SELECTION_CELL_WIDTH)}
                      />
                    </MaterialTableRow>
                  )),
                )
              }
            </MaterialTable>
          </div>
        </Collapse>
      </MaterialTableCell>
    </MaterialTableRow>)
  }, [
    classes,
    collapsed,
    cx,
    getSubRows,
    groupedChildren.SubRow,
    isMobile,
    sharedComponentsByDataRowIndex,
    stickyScroll,
  ])

  const renderChevron = useCallback((dataRowIndex: number, hideChevron: boolean) => {
    const rowCollapsed = collapsed[dataRowIndex]
    return hideChevron
      ? <MaterialTableCell
        className={cx(classes.tableCellChevron)}
        padding='none'
        width={px(SELECTION_CELL_WIDTH)}
      />
      : <MaterialTableCell
        align='right'
        className={cx(classes.tableCellChevron)}
        padding='none'
        width={px(SELECTION_CELL_WIDTH)}
      >
        { rowCollapsed ? <ExpandLess /> : <ExpandMore /> }
      </MaterialTableCell>
  }, [collapsed, cx, classes])

  const sortOptions = useMemo(() => flatten(compact(
    groupedChildren.RowCell.map(({
      props: {
        // @ts-expect-error TODO: Fix: Property 'sortKey' does not exist on type 'RowCellComponentType<any, any, any>'.ts(2339)
        sortKey,
      },
    }) => (
      sortKey
        ? [
          {
            label: `${columnNameReplacer(sortKey)} (Ascending)`,
            value: JSON.stringify({
              sortBy: sortKey,
              sortDirection: 'asc',
            }),
          },
          {
            label: `${columnNameReplacer(sortKey)} (Descending)`,
            value: JSON.stringify({
              sortBy: sortKey,
              sortDirection: 'desc',
            }),
          },
        ]
        : null
    )))), [groupedChildren])

  return (<>
    { isMobile && some(sortOptions) && <Box
      pb={0}
      pt={1}
    >
      { pagination && useTablePagination && <TableSorter
        sortOptions={sortOptions}
        useTablePagination={useTablePagination}
      /> }
    </Box> }
    <div className={classes.tableWrapper}>
      { /* @ts-expect-error TODO: Fix: small not valid prop. Maybe MUI types version issue? */ }
      <MaterialTable
        className={classes.table}
        small={isMobile}
        stickyHeader={stickyHeader}
        style={stickyScroll ? { tableLayout: 'fixed' } : undefined}
      >
        {
          renderHead && !(isMobile && RowBannerChildEl) && <MaterialTableHead>
            <MaterialTableRow>
              {
                groupedChildren.RowCell.map(({
                  props: {
                    // @ts-expect-error Property 'align' does not exist on type 'RowCellComponentType<any, any, any>'.ts(2339)
                    align,
                    // @ts-expect-error Property 'id' does not exist on type 'RowCellComponentType<any, any, any>'.ts(2339)
                    id,
                    // @ts-expect-error Property 'label' does not exist on type 'RowCellComponentType<any, any, any>'.ts(2339)
                    label,
                    // @ts-expect-error Property 'sortKey' does not exist on type 'RowCellComponentType<any, any, any>'.ts(2339)
                    sortKey,
                  },
                }, index: number) => (
                  <MaterialTableCell
                    align={align || 'center'}
                    className={cx(classes.tableHeadCell, { [classes.withBorderTop]: !!borderTop })}
                    key={index} // eslint-disable-line react/no-array-index-key
                    onClick={() => {
                      if (sortKey && pagination && onChangeSortBy) onChangeSortBy(sortKey, sortDirection === 'asc' ? 'desc' : 'asc')
                    }}
                  >
                    { sortKey
                      ? <TableSortLabel
                        isSorting={sortBy === (sortKey || id)}
                        label={label}
                        sortDirection={sortDirection}
                        sortIconBefore={!!sortIconBefore}
                        tooltipPlacement={tooltipPlacement ?? 'bottom'}
                        tooltipTitle={onlyText(label)}
                      />
                      : label }
                  </MaterialTableCell>
                ))
              }
              {
                !isEmpty(groupedChildren.SubRow) && <MaterialTableCell
                  align='right'
                  className={cx(classes.tableHeadCell, { [classes.withBorderTop]: !!borderTop })}
                  key='chevron-cell'
                  padding='none'
                  width={px(SELECTION_CELL_WIDTH)}
                />

              }
            </MaterialTableRow>
          </MaterialTableHead>
        }
        <MaterialTableBody data-test-id={`${testID}-TableContent`}>
          {
            !(isMobile && RowBannerChildEl) && data.map((dataRow, dataRowIndex: number) => (<>
              <MaterialTableRow
                data-test-id={`${testID}-Table-row-${dataRowIndex}`}
                key={`row-${dataRowIndex}`} // eslint-disable-line react/no-array-index-key
                onClick={() => !isEmpty(groupedChildren.SubRow) && handleExpandRow(dataRowIndex)}
              >
                {
                  groupedChildren.RowCell.map(({
                    props: {
                      // @ts-expect-error Property 'children' does not exist on type 'RowCellComponentType<any, any, any>'.ts(2339)
                      children,
                      ...rest
                    },
                  }, index: number) => (
                    // @ts-expect-error Type '{ children: any; propTypes?: WeakValidationMap<RowCellCommonProps<any> & { children: (b: any, a: any) => ReactNode; }> | undefined; contextTypes?: ValidationMap<...> | undefined; defaultProps?: Partial<...> | undefined; displayName?: string | undefined; key: number; }' is missing the following properties from type 'RowCellCommonProps<any>': id, labelts(2739)
                    <RowCellCloned
                      className={rowClassName?.(dataRow, dataRowIndex)}
                      key={index} // eslint-disable-line react/no-array-index-key
                      stickyScroll={stickyScroll}
                      {...rest}
                    >
                      { children(dataRow, sharedComponentsByDataRowIndex ? sharedComponentsByDataRowIndex[dataRowIndex] : undefined) }
                    </RowCellCloned>
                  ))
                }
                { !isEmpty(groupedChildren.SubRow) && renderChevron(dataRowIndex, dataRow.hideChevron) }
              </MaterialTableRow>

              { renderSubRows(dataRow, dataRowIndex) }
            </>))
          }
          {
            !isMobile && groupedChildren.ExtraRow.map(({
              props: {
                // @ts-expect-error Property 'children' does not exist on type 'ExtraRowComponentType'.ts(2339)
                children,
              },
            }, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <MaterialTableRow key={index}>
                {
                  Children.toArray(children).map(({
                    // @ts-expect-error Property 'props' does not exist on type 'ReactChild | ReactFragment | ReactPortal'.ts(2339)
                    props:
                    { children: cellChildren, ...rest },
                  }, index: number) => (
                    <ExtraRowCellCloned
                      key={index} // eslint-disable-line react/no-array-index-key
                      {...rest}
                    >
                      { cellChildren() }
                    </ExtraRowCellCloned>
                  ))
                }
              </MaterialTableRow>
            ))
          }
          {
            (isMobile && RowBannerChildEl) && data.map((dataRow, dataRowIndex: number) => ( // eslint-disable-next-line react/no-array-index-key
              <tr key={dataRowIndex}>
                { groupedChildren.RowBanner.map(({
                  props: {
                    // @ts-expect-error Property 'children' does not exist on type 'RowBannerComponentType<any, any>'.ts(2339)
                    children,
                  },
                }, index: number) => (
                  <MaterialTableCell
                    classes={{
                      root: borderlessCells ? classes.tableCellWithDivider : classes.tableCell,
                    }}
                    key={index} // eslint-disable-line react/no-array-index-key
                  >
                    <Flexbox
                      className={cx({ [classes.tableCellBody]: !borderlessCells })}
                      key={index} // eslint-disable-line react/no-array-index-key
                      vertical
                    >
                      <Box
                        mx={1}
                      >
                        { children(dataRow, sharedComponentsByDataRowIndex ? sharedComponentsByDataRowIndex[dataRowIndex] : undefined) }
                      </Box>
                      { some(mobileCells) && (<>
                        <Divider
                          noMarginBottom
                          noMarginTop
                          sideMargin
                        />
                        <Box mx={1}>
                          <Flexbox
                            align='spaceBetween'
                            vertical={mobileTableMode === 'vertical'}
                          >
                            {
                              // @ts-expect-error Property 'props' does not exist on type 'number | React$Element<RowCellComponentType<any, any, any>> | (<U>(callbackfn: (value: React$Element<RowCellComponentType<any, any, any>>, index: number, array: React$Element<...>[]) => U, thisArg?: any) => U[]) | ... 32 more ... | (<A, D extends number = 1>(this: A, depth?: D | undefined) => FlatArray<...>[])'.ts(2339)
                              mobileCells.map(({ props: { children: cellChildren, ...cellRest } }, index: number) => (<RowCellMetadataClonedComponent
                                key={index} // eslint-disable-line react/no-array-index-key
                                {...cellRest}
                              >
                                {
                                  cellChildren(dataRow, sharedComponentsByDataRowIndex ? sharedComponentsByDataRowIndex[dataRowIndex] : undefined)
                                }
                              </RowCellMetadataClonedComponent>))
                            }
                          </Flexbox>
                        </Box>
                      </>) }
                    </Flexbox>
                  </MaterialTableCell>
                )) }
              </tr>))
          }
          {
            isMobile && groupedChildren.ExtraRow.map(({
              props: {
                // @ts-expect-error Property 'children' does not exist on type 'ExtraRowComponentType'.ts(2339)
                children,
              },
            }, index: number) => (
              <tr
                className={classes.noBottomBorder}
                key={index} // eslint-disable-line react/no-array-index-key
              >
                {
                  Children.toArray(children).map(({
                    // @ts-expect-error Property 'props' does not exist on type 'ReactChild | ReactFragment | ReactPortal'.ts(2339)
                    props: { children: cellChildren, ...rest },
                  }, index: number) => (
                    <ExtraRowCellCloned
                      key={index} // eslint-disable-line react/no-array-index-key
                      {...rest}
                    >
                      { cellChildren() }
                    </ExtraRowCellCloned>
                  ))
                }
              </tr>))
          }
          { !disableDefaultPagination && pagination && useTablePagination && <TablePagination
            footerNoBorder={!!footerNoBorder}
            totalRowCount={totalRowCount}
            useTablePagination={useTablePagination}
          /> }
        </MaterialTableBody>
      </MaterialTable>
    </div>
  </>)
}
